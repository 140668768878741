.SnackbarContainer-top .MuiCollapse-root {
  width: 70vw;
}

@media (max-width: 600px) {
  .SnackbarContainer-top .MuiCollapse-root {
    width: 90vw;
  }
}

.SnackbarContent-root .SnackbarItem-message {
  max-width: 88% !important;
}

.SnackbarContent-root.SnackbarItem-variantWarning {
  background-color: #fff7cd !important;
}

.SnackbarContent-root.SnackbarItem-variantWarning
  .SnackbarItem-message
  span {
  background-color: #fff7cd !important;
}

.SnackbarContent-root.SnackbarItem-variantSuccess {
  background-color: #e9fcd4 !important;
}

.SnackbarContent-root.SnackbarItem-variantSuccess
  .SnackbarItem-message
  span {
  background-color: #e9fcd4 !important;
}

.SnackbarContent-root.SnackbarItem-variantError {
  background-color: #ffe7d9 !important;
}

.SnackbarContent-root.SnackbarItem-variantError
  .SnackbarItem-message
  span {
  background-color: #ffe7d9 !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: '#2A2936';
}

::-webkit-scrollbar-thumb {
  background: #8d8a9c3d;
  border-radius: 4px;
}
